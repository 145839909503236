<template>
     <div id="valdifassa">
        <v-container fluid >
         <v-container class="mt-5 pt-5">
           <breadcrumbs></breadcrumbs>
         </v-container>
         <v-container>
          <v-row class="align-content-center align-center">
            <v-col cols="8" md="4" class="text-center mx-auto">
              <v-img src="/img/assets/val-di-fassa.jpg" alt="Val di Fassa" class="mx-auto"></v-img>
            </v-col>
            <v-col v-if="language == 'it'" cols="12" md="8" class="mx-auto">
              <h1 class="mb-2">Val di Fassa</h1>
              Pozza di Fassa è situato a m. 1320 in una conca solatia nel punto di maggiore ampiezza della valle nella confluenza tra l’Avisio e il Rio San Nicolò. D’inverno è molto frequentata la rinnovata skiarea Buffaure, apprezzata da tutti per i panorami e la tranquillità e lo Ski Stadium Val di Fassa, che viene illuminato a giorno per lo sci in notturna.
              <br>
              Ciasa Nü si trova proprio di fronte al Ski Stadium e a 100m dall impianto di risalita del Buffaure porta di accesso a tutto il comprensorio dell area Superski Dolomiti
              <br>
              Salendo pochi metri lungo la via si attraversa, anche in slittino , il prato che porta all area Fraine, il paradiso dei bambini e per i principianti dello sci. Per gli appassionati dello sci di fondo l anello è anche’esso appena dietro casa, ma non possiamo non ricordare da Pozza si accede al circuito della Marcialonga, una valida opportunità con l’anello di Ciancoal, divertente per la varietà dei suoi ritmi e per l’innevamento sempre assicurato, particolarmente suggestivo anche in notturna. In estate: escursioni, passeggiate, roccia, geologia, dai monti del Catinaccio ai Dirupi del Larsec.
              <br>
              Sempre a piedi da ciasanu si arriva comodamente agli impianti di risalita del Catinaccio da dove partono le passeggiate piu famose verso il rifugio Gardeccia e le cime del Vaiolet.
            </v-col>
            <v-col v-if="language == 'en'" cols="12" md="8" class="mx-auto">
              <h1 class="mb-2">Val di Fassa</h1>
              Pozza di Fassa is located at m. 1320 in a sunny basin at the widest point of the valley at the confluence of the Avisio and Rio San Nicolò. In winter the renovated Buffaure ski area is very popular, appreciated by all for the views and tranquility and the Val di Fassa Ski Stadium, which is floodlit for night skiing.
               <br>
               Ciasa Nü is located right in front of the Ski Stadium and 100m from the Buffaure ski lift, gateway to the entire Superski Dolomiti area
               <br>
               Going up a few meters along the road, you cross the meadow that leads to the Fraine area, a paradise for children and for ski beginners, even by toboggan. For cross-country skiing enthusiasts, the ring is also just behind the house, but we cannot fail to mention that from Pozza you can access the Marcialonga circuit, a valid opportunity with the Ciancoal ring, fun for the variety of its rhythms and for snow is always guaranteed, particularly impressive even at night. In summer: excursions, walks, rocks, geology, from the Catinaccio mountains to the Larsec ravines.
               <br>
               Always on foot from Ciasanu you can easily reach the Catinaccio ski lifts from where the most famous walks start towards the Gardeccia refuge and the Vaiolet peaks.
            </v-col>
          </v-row>
         </v-container>
        </v-container>
     </div>
   </template>
   
   <script>
    // var files = require.context(
    //               '@/assets/photos',
    //               true,
    //               /^.*\.jpg$/
    //             )
    // console.log("FILES",files.keys());

     export default {
       name: 'Home',
       data: () => ({
        language : null,
       }),
       created() {
        this.language = this.$i18n.locale
       }
     }
   </script>
   <style scoped>
   #home{
     min-height: 100%;
   }
   </style>